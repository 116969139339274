<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M16 7.0666V28.44C16.2267 28.44 16.4667 28.3999 16.6533 28.2932L16.7067 28.2666C19.2667 26.8666 23.7333 25.4 26.6266 25.0133L27.0133 24.9599C28.2933 24.7999 29.3333 23.5999 29.3333 22.3199V6.21325C29.3333 4.62658 28.04 3.42659 26.4534 3.55993C23.6534 3.78659 19.4134 5.19996 17.04 6.67996L16.7067 6.87991C16.5067 6.99991 16.2533 7.0666 16 7.0666Z"
      fill="#9984D4"
    />
    <path
      d="M2.6665 6.22653V22.3199C2.6665 23.5999 3.70649 24.7998 4.98649 24.9598L5.42651 25.0132C8.33318 25.3999 12.8132 26.8798 15.3732 28.2932C15.5465 28.3998 15.7598 28.4399 15.9998 28.4399V7.06654C15.7465 7.06654 15.4931 6.99985 15.2931 6.87985L15.0665 6.7332C12.6932 5.23987 8.43981 3.81321 5.63981 3.57321H5.55981C3.97315 3.43988 2.6665 4.62653 2.6665 6.22653Z"
      fill="#9984D4"
    />
    <path
      d="M25.3333 3.70654V9.42651C25.3333 9.95985 24.7466 10.2798 24.2933 9.98649L22.6667 8.90649L21.04 9.98649C20.6 10.2798 20 9.95985 20 9.42651V5.22648C21.7467 4.53315 23.6933 3.97321 25.3333 3.70654Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "RulesSvg",
};
</script>

<style scoped></style>
