<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-rules">
        <div class="popup-swipe" v-touch:swipe.bottom="closeModal">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ rulesTitle }}
          </div>
          <PageButton @click="closeModal">
            <template v-slot:icon>
              <Close />
            </template>
          </PageButton>
        </div>
        <div class="popup__content">
          <div class="popup-rules__img" v-if="isGameModal">
            <img src="@/assets/img/game-title.png" alt="" />
          </div>

          <div class="popup__subtitle">
            {{ $t("game-page-notice") }}
          </div>

          <ul class="rules-list">
            <li class="rules-list__item">
              <div class="rules-list__icon">
                <ListIconSvg />
              </div>

              <div class="rules-list__content">
                <div class="rules-list__title">
                  {{ $t("rules-item-title") }} 01
                </div>

                <div class="default-text">
                  <p>{{ $t("rules-text-1") }}</p>
                  <p>{{ $t("rules-text-2") }}</p>
                </div>
              </div>
            </li>
            <li class="rules-list__item">
              <div class="rules-list__icon">
                <ListIconSvg />
              </div>

              <div class="rules-list__content">
                <div class="rules-list__title">
                  {{ $t("rules-item-title") }} 02
                </div>

                <div class="default-text">
                  <p>{{ $t("rules-text-3") }}</p>
                </div>
              </div>
            </li>
            <li class="rules-list__item">
              <div class="rules-list__icon">
                <ListIconSvg />
              </div>

              <div class="rules-list__content">
                <div class="rules-list__title">
                  {{ $t("rules-item-title") }} 03
                </div>

                <div class="default-text">
                  <p>{{ $t("rules-text-4") }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import ListIconSvg from "../icons/ListIconSvg";
export default {
  name: "GameRulesModal",
  mixins: [CloseModalMixin],
  components: { ListIconSvg, Close, PageButton },
  props: {
    rulesTitle: {
      type: String,
    },
    isGameModal: {
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.close();
    },
  },
};
</script>

<style scoped></style>
