<template>
  <div class="page-events game-page demo-page">
    <div class="game-page__logo">
      <img src="@/assets/img/game-title.png" alt="" />
    </div>

    <div class="page-events__chars">
      <div
        class="mates-char"
        @click="openCollection"
        :class="{ active: character }"
      >
        <div class="mates-char__wrap" v-if="!character">
          <RoundedButton>
            <template v-slot:icon>
              <PlusSvg />
            </template>
          </RoundedButton>

          <p class="mates-char__text">{{ $t("1-char") }}</p>
        </div>

        <div class="mates-char__wrap" v-else>
          <button
            class="mates-char__edit"
            @click.stop="character = null"
            v-if="allowEditCharacter"
          >
            Edit
          </button>
          <div class="mates-char__img">
            <character-model :image="character.image" />
          </div>

          <div class="char-stats">
            <div
              v-for="([key, value], index) in Object.entries(character.skills)"
              :key="index"
              class="char-stats__row"
            >
              <div class="char-stats__title">
                {{ $t(`stat-${key}`) }}
              </div>

              <div class="char-stats__amount">{{ value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GameRecharge v-if="!gameAllowed" />

    <div class="demo-page__wrap" v-if="gameAllowed">
      <div class="game-page__subtitle">
        {{ $t("how-to-title") }}
      </div>

      <div class="default-text">
        {{ $t("how-to-text") }}
      </div>
    </div>

    <Rules
      v-if="gameAllowed"
      :title="$t('rules-game-title')"
      :text="$t('rules-game-text')"
      @click="isRulesModal = true"
    />

    <button
      class="game-btn"
      @click="startDemoGame"
      :disabled="!character"
      v-if="gameAllowed"
    >
      {{ $t("game-demo-btn") }}
    </button>
    <transition name="fade">
      <MyCollection
        v-if="isMyCollModal"
        @close="isMyCollModal = false"
        @selectMate="selectMate"
        :show-character-commissions="false"
        :show-character-stats="true"
        :sort-type="'SKILLS'"
        :show-filter="false"
      />
    </transition>
    <GameRulesModal
      :rules-title="$t('rules-game-title')"
      v-if="isRulesModal"
      @close="isRulesModal = false"
      :is-game-modal="true"
    />
  </div>
</template>

<script>
import MyCollection from "../../components/modals/MyCollection";
import RoundedButton from "../../components/common/buttons/RoundedButton";
import PlusSvg from "../../components/icons/PlusSvg";
import Rules from "../../components/common/Rules";
import GameRulesModal from "../../components/modals/GameRulesModal";
import CharacterModel from "../../components/character/СharacterModel";
import setProcessingMixin from "../../mixins/general/SetProcessingMixin";
import api from "../../api/api";
import GetSpaceInvidersInfoMixin from "../../mixins/game/GetSpaceInvidersInfoMixin";
import GameRecharge from "./GameRecharge";

export default {
  name: "DemoGame",
  mixins: [setProcessingMixin, GetSpaceInvidersInfoMixin],
  data() {
    return {
      character: null,
      isMyCollModal: false,
      isRulesModal: false,
      gameAllowed: true,
      allowEditCharacter: true,
    };
  },
  methods: {
    openCollection() {
      if (!this.gameAllowed) {
        return;
      }
      this.isMyCollModal = true;
    },
    async selectMate(item) {
      this.character = item;
      this.isMyCollModal = false;
    },
    async isGameAllowed() {
      try {
        const response = await api.getUserCryptoshoppies({
          limit: 1,
          skip: 0,
        });
        return response.total >= 1;
      } catch {
        return false;
      }
    },
    async startDemoGame() {
      try {
        this.allowEditCharacter = false;
        this.setLoading(true);
        await api.startDemoGame({
          cryptoshoppie: this.character.id,
        });
        this.setLoading(false);
        await this.$router.push({
          path: "mini-game",
        });
      } catch (e) {
        this.setLoading(false);
        this.setError({
          message: e.response.data.error.message,
        });
      }
    },
  },
  async mounted() {
    const response = await this.getSpaceInvadersInfo();
    if (response.data.isDemoGamePassed) {
      await this.$router.push({
        path: "mini-game",
      });
      return;
    }
    this.gameAllowed = await this.isGameAllowed();
    const character = this.$route.params.character;
    if (this.gameAllowed && !!character) {
      this.character = character;
    }
  },
  components: {
    GameRecharge,
    CharacterModel,
    GameRulesModal,
    Rules,
    PlusSvg,
    RoundedButton,
    MyCollection,
  },
};
</script>

<style scoped></style>
