<template>
  <div class="rules-box" @click="goTo">
    <div class="rules-box__icon">
      <RulesSvg />
    </div>

    <div class="rules-box__wrap">
      <div class="rules-box__title">
        {{ title }}
      </div>

      <div class="default-text">
        {{ text }}
      </div>
    </div>

    <RoundedButton>
      <template v-slot:icon>
        <ArrowRight />
      </template>
    </RoundedButton>
  </div>
</template>

<script>
import RulesSvg from "../icons/RulesSvg";
import RoundedButton from "./buttons/RoundedButton";
import ArrowRight from "../icons/ArrowRight";
export default {
  name: "Rules",
  components: { ArrowRight, RoundedButton, RulesSvg },
  props: {
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
  methods: {
    goTo() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
