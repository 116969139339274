import api from "../../api/api";

export default {
  methods: {
    async getSpaceInvadersInfo() {
      try {
        return await api.getSpaceInvadersInfo();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
