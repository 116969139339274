<template>
  <div class="game-error">
    <div class="page-events__title">
      {{ $t("game-error-title") }}
    </div>

    <div class="page-events__notice">
      {{ $t("game-error-notice") }}
      <router-link to="market" class="link">{{ $t("market") }}</router-link>
      {{ $t("or") }}
      <router-link to="events" class="link">{{ $t("events") }}</router-link>
      {{ $t("page") }}
    </div>

    <Rules
      :text="$t('rules-chars-text')"
      :title="$t('rules-chars-title')"
      @click="isRulesModal = true"
    />

    <GameRulesModal
      :rules-title="$t('rules-chars-title')"
      v-if="isRulesModal"
      @close="isRulesModal = false"
    />
  </div>
</template>

<script>
import Rules from "../../components/common/Rules";
import GameRulesModal from "../../components/modals/GameRulesModal";
export default {
  name: "GameRecharge",
  components: { GameRulesModal, Rules },
  data() {
    return {
      isRulesModal: false,
    };
  },
};
</script>

<style scoped></style>
